@import 'src/styles/variables';
@import 'src/styles/mixins';

.actionsPopUp {
  background-color: $white;
  border-radius: 12px;
  width: 360px;
  height: auto;

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;

    & > p {
      @include menuFont; 
      color: $primary100;
    }

    & > button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      border: 0;
      background-color: transparent;
      outline: 0;
      cursor: pointer;

      & > svg {
        width: 100%;
        height: auto;
        path{
          fill : $primary100;
          stroke: $primary100;
        }
      }
    } 
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    button:first-child{
      & > svg {
         path{
           stroke: $primary100;
         }
      }
    }
    button:nth-child(2){
      & > svg {
        path:first-child{
          fill: $primary100;
        }
        path:not(:first-child){
          stroke: $primary100;
        }
      }
    }
  }
}
