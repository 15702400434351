$montserratFontStyle: 'Montserrat', sans-serif;
$ralewayFontStyle: 'Raleway', sans-serif;

$regularFontWeight: 400;
$semiBoldFontWeight: 600;
$boldFontWeight: 700;

$white: #FFFFFF;

$primary100: var(--primary100, #3B566F);
$primary80: var(--primary80, #53787F);
$primary60: var(--primary60, #6687BA);
$primary40: var(--primary40, #99AFD1);
$primary20: var(--primary20, #CCD7E8);
$primary10: var(--primary10, #E6EBF4);
$primary6: var(--primary6, #F0F3F8);
$primary4: var(--primary4, #F5F7FA);
$primary2: var(--primary2, #FAFBFD);

$black100: var(--black100, #000E24);
$black80: var(--black80, #333E50);
$black60: var(--black60, #666E7C);
$black20: var(--black20, #CCCFD3);
$black10: var(--black10, #E0E2E5);
$black4: var(--black4, #F5F5F6);

$red100: #CD0C0C;
$green100: #0EAA00;

$inputDropdownIndex: 9;
$popupIndex: 10;

$formSectionMargin: 56px;
$productsTableGridColumnSize: var(--productsTableGridColumnSize, 24% 30% 13% 13% 8% 13%);

$gradientFirstColor: var(--gradientFirstColor, #3B566F);
$gradientSecondColor: var(--gradientSecondColor, #53787F);
$gradient: linear-gradient(
  180deg,
  $gradientFirstColor 0%,
  $gradientSecondColor 100%
);
