@import 'src/styles/mixins';
@import 'src/styles/variables';

.companyAccounts {
  margin-bottom: 24px;
}

.loanCriteria {
  margin-bottom: 48px;
}

.bottomBar {
  margin-bottom: 32px;
  @include formSectionContainer;
}

.creditProfile {
  margin-bottom: 45px;

  @include xl {
    margin-bottom: 0;
    flex-grow: 1;
  }
}

.propertyInformation {
  margin-bottom: 32px;

  @include xl {
    margin-bottom: 0;
    flex-grow: 1;
  }
}

.submitContainer {
  display: flex;
  justify-content: center;

  @include xl {
    justify-content: flex-end;
  }
}

.submit {
  width: 100%;
  max-width: 500px;
  min-height: 56px;

  @include xl {
    max-width: unset;
    width: calc(100% - #{$formSectionMargin} / 2);
  }
}

.clearAll {
  width: 100%;
  max-width: 500px;
  margin-top: 32px;

  @include xl {
    margin-top: 0;
    max-width: 230px;
  }
}

.clearAllContainer {
  display: flex;
  justify-content: center;

  @include xl {
    display: block;
  }
}

.buttons {
  margin-top: 32px;
  display: flex;
  flex-direction: column-reverse;

  @include xl {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
