@import 'src/styles/media';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  background: linear-gradient(160.65deg, rgba(248, 250, 252, 0.5) 46.35%, #E3EAF2 100%), $white;
  @include md {
    flex-direction: row;
    justify-content: end;
  }
}


.footer {
  display: table;
  margin: 0 32px;
  width: calc(100vw - 64px);
  position: fixed;
    bottom: 0;
  order:3;
  border-top: 1px solid rgba(204, 207, 211, 0.2);
  height: 68px;
  @include productTableVariableName;
  letter-spacing: unset;

  @include md {
   position: fixed;
    bottom: 0;
    display: table;
    height: 82px;
    width: calc(67vw - 240px);
    right: 0;
    margin: 0 120px;
    color: rgba($color: $white, $alpha: 0.6);
    font-size: 12px;
  }

  >p {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    letter-spacing: -0.7px;
    @include md {
      text-align: unset;
      letter-spacing: normal;
    }
  }

}

.container {
  box-sizing: border-box;
  order: 2;
  height: 100vh;

  @include md {
    order: 1;
    height: 100vh;
    width: 33vw;
    position: fixed;
    left: 0;
    top: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin: 0 32px;
  height: 100%;
  max-width: 360px;

  @media screen and (min-width: 431px){
    margin: 0 auto;
  }


  @include xl {
    padding: 48px 0 0;
    box-sizing: border-box;
    max-width: 360px;
  }

}

.contentContainer {
  display: flex;
  flex-direction: column;
  padding-top: 8px;

  @include xl {
    padding-top: 24px;
    max-width: 360px;
    align-self: center;
  }
}

.form{
  display: flex;
  flex-direction: column;
  justify-content: space-between;


  @include md{
    height: 100vh;
  }
}

.formWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 0;
}

.contentWrapper {
  @include paragraph;
  padding: 30px 0;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;

  button {
    width: 100%;
  }

  &>label {
    font-weight: $semiBoldFontWeight;
    color: $black60;
    display: flex;
    gap: 9px;
    flex-direction: column;
    width: 100%;
  }

  &>div {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    width: 100%;
  }

}

.buttonContainer{
    display: block;
    padding-bottom: 48px;
}

