@import 'src/styles/variables';
@import 'src/styles/media';

.container {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 14, 36, 0.5);
  z-index: $popupIndex;
  padding: 12px 32px 120px 12px;
  overflow-y: scroll;
  box-sizing: border-box;
  right: -20px;

  @include xl {
    padding: 12px 32px 12px 12px;
  }
}

.bodyHidden {
  overflow: hidden;
}

.contextualPopup {
  div:nth-child(3){
  border-radius: 12px;
}
}