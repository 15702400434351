@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  box-sizing: border-box;
  order: 2;
  height: calc(100% - 268px);
  padding-bottom: 24px;

  @include md {
    order: 1;
    height: 100vh;
    width: 33vw;
    position: fixed;
    left: 0;
    top: 0;
    padding-bottom: unset;
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin: 0 32px;
  height: 100%;
  max-width: 360px;

  @media screen and (min-width: 431px){
    margin: 0 auto;
  }

  @include xl {
    padding: 48px 0 0;
    box-sizing: border-box;
    max-width: 360px;
  }
  >div:first-child {
    @include md{
      position: fixed;
    }
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  padding-top: 8px;

  @include xl {
    padding-top: 24px;
    max-width: 360px;
    align-self: center;
  }
}

.form{
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @include md{
    height: 100vh;
  }
}

