@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.contentWrapper {
  @include paragraph;
  padding-top: 30px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;

  button {
    width: 100%;
  }

  .backBtn{
    width: 56px;
    >p{
      height: 24px;
    }
  }

  label {
    font-weight: $semiBoldFontWeight;
    color: $black60;
    display: flex;
    gap: 9px;
    flex-direction: column;
    width: 100%;

    &.inputError{
      color: $red100;
    
      input{
        border-color: $red100;
      }
    }
  }

  &>div {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    width: 100%;
  }

  .buttonsContainer {
    display: flex;
    flex-direction: row;
  }
}

.pageDescription {
  display: flex;
  flex-direction: column;

  strong {
    font-weight: $boldFontWeight;
  }
}

.formWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.resendText{
  align-self: start;
  @include smallFont;
}

.linkContainer {
  display: flex;
  align-self: start;
  cursor: pointer;
  width: max-content;

  .title {
    font-family: $montserratFontStyle;
    align-self: start;
    text-decoration: underline;
    letter-spacing: -0.2px;
    color: #4A768D;
   
    @include smallFont;
  }
}

.infoBlock{
  @include grossFont;
  span {
    color: #4A768D;
    text-decoration: underline;
  }
  .resendLinkOn{
   cursor: pointer;
  }
  .resendLinkOff{
    cursor: not-allowed;
   }
}
.resetContainer {
  display: flex;
  align-self: center;
  cursor: pointer;
  width: max-content;

  .title {
    font-family: $montserratFontStyle;
    align-self: center;
    text-decoration: underline;
    letter-spacing: -0.5px;
    margin-left: 8px;
  
   
    @include grossFont;
  }
}
.skeleton{
  & div{
    margin: 24px 0;
  }
}

