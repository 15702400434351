@import 'src/styles/variables';
@import 'src/styles/mixins';

.formTitle {
  @include h1;
  margin-bottom: 12px;
  align-self: start;

  @include md {
    font-weight: $semiBoldFontWeight;
    font-size: 40px;
    line-height: 48px;
  }
}

.formSubtitle {
  align-self: start;
  @include productTableVariableValue;
  font-weight: $regularFontWeight;
  @include grossFont;
}
