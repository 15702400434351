@import 'styles/variables';
@import 'styles/mixins';

$showIcon: url('images/eye closed.svg') no-repeat center;
$hideIcon: url('images/eye opened.svg') no-repeat center;

.inputContainer {
  width: 100%;
  position: relative;

  input {
    @include input;
    padding-right: 32px;
  }

  button {
    position: absolute;
    top: 14px;
    right: 12px;
    border: 0;
    background-color: transparent;
    outline: none;
    width: 24px;
    height: 24px;

    & svg {
      stroke: $black100;
      width: 100%;
      height: auto;
    }

    &.showButton {
      background-color: $black100;
      mask: $showIcon;
      transition: all 0.25s ease-in-out;
      cursor: pointer;
      margin-top: 0;
    }

    &.hideButton {
      @extend .showButton;
      mask: $hideIcon;
    }

    &.disabledButton {
      @extend .showButton;
      cursor: default;
    }
  }
}


