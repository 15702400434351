@import 'src/styles/media';
@import 'src/styles/mixins';

.container {
  order: 1;
  display: flex;
  height: 200px;
  width: 100vw;
  padding: 0px 32px;
  background-image: url('/images/background.svg');
  box-sizing: border-box;

  @include md {
    order: 2;
    max-width: unset;
    flex-direction: column;
    justify-content: flex-end;
    width: 67vw;
    padding: 0 120px 82px;
    height: 100vh;
    
  }
}

.textContainer {
  max-width: 564px;
  margin: 0 auto;

  @include md {
    max-width: unset;
  }
}

.title {
  align-self: end;
  padding-bottom: 12px;
  svg{
    width: 212px;
    height: 80px;
  }
  @include md {
   align-self: start;
   padding-bottom: 24px;
   svg{
    width: 312px;
    height: 120px;
  }
  }
}


