@import 'src/styles/media';
@import 'src/styles/variables';

.headerContainer {
  padding: 16px 32px;
  background: transparent;
  height: 44px;

  @include xl {
    padding: 16px 0;
    background: $white;
  }

  .headerLogo {
    width: 190px;

    svg {
      width: 100%;
 
    }
  }
}

.headerInBlock {
  padding: 24px 0;
  background: transparent;

  @include xl{
    padding: 8px 0;
  }

  @include xll {
   // padding: 32px 0;
  }

  .headerLogo {
    width: 190px;

    @include xl {
      width: 224px;
    }

    svg {
      width: 100%;
      height: 48px;
    }
  }
}
