@import 'src/styles/media';
@import 'src/styles/variables';

@mixin tinyFont {
  font-size: 8px;
  line-height: 12px;
}

@mixin smallFont {
  font-size: 12px;
  line-height: 18px;
}

@mixin middleFont {
  font-size: 14px;
  line-height: 18px;
}

@mixin grossFont {
  font-size: 16px;
  line-height: 24px;
}

@mixin hugeFont {
  font-size: 18px;
  line-height: 24px;
}

@mixin paragraph {
  font-family: $montserratFontStyle;
  letter-spacing: -0.5px;
  @include middleFont;
}

@mixin h1 {
  font-family: $ralewayFontStyle;
  font-weight: $boldFontWeight;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1.2px;
  color: $primary80;

  @include lg {
    font-weight: $semiBoldFontWeight;
    font-size: 40px;
    line-height: 48px;
  }
}

@mixin h2 {
  font-family: $ralewayFontStyle;
  font-weight: $boldFontWeight;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: -1.2px;
  color: $primary100;

  @include lg {
    font-weight: $semiBoldFontWeight;
    font-size: 32px;
    line-height: 40px;
  }
}

@mixin menuFont{
  font-family: $ralewayFontStyle;
  font-weight: $boldFontWeight;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
}

@mixin h3 {
  @include menuFont;

  @include lg {
    font-size: 24px;
    line-height: 28px;
  }
}

@mixin h4 {
  font-family: $ralewayFontStyle;
  font-style: normal;
  font-weight: $boldFontWeight;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  @include grossFont;

  @include lg {
    @include hugeFont;
  }
}

@mixin slogan{
  font-family: $ralewayFontStyle;
  font-size: 40px;
  font-style: normal;
  font-weight: $semiBoldFontWeight;
  letter-spacing: -2.4px;
  line-height: 48px;
  color: $white;

  @include md {
    font-size: 80px;
    line-height: 96px;
  }
}

@mixin input {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-height: 50px;
  padding: 16px 24px;
  border: 1px solid $primary20;
  border-radius: 100px;
  font-weight: $semiBoldFontWeight;
  color: $black100;
  outline: none;
  text-overflow: ellipsis;
  cursor: pointer;
  appearance: none;
  @include paragraph;

  &:hover {
    background: $primary2;
    border: 1px solid $primary40;
  }

  &:focus {
    border: 1px solid $primary20;
    box-shadow: 0 0 0 3px $primary10;
  }

  &:disabled {
    opacity: 1;
    -webkit-text-fill-color: $black60;
    background: $black4;
    color: $black60;
    border: none;
  }
}

@mixin inputLabel {
  font-style: normal;
  font-weight: $semiBoldFontWeight;
  @include paragraph;
}

@mixin dropdownOptionsList {
  position: absolute;
  width: 100%;
  background: $white;
  border: 1px solid $primary20;
  box-sizing: border-box;
  box-shadow: 0 8px 24px -6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  list-style-type: none;
  z-index: $inputDropdownIndex;
  padding: 4px;
}

@mixin dropdownOption {
  padding: 12px 52px 12px 12px;
  border-radius: 8px;
  font-weight: $regularFontWeight;
  color: $black60;
  @include paragraph;

  &:hover {
    background: $primary6;
    color: $black100;
    cursor: pointer;
  }
}

@mixin productTableHeader {
  display: none;

  @include lg {
    display: grid;
  }
}

@mixin productTableRowContainer {
  background: transparent;
  border: none;
  border-bottom: 1px solid $primary20;
  border-radius: 0;
  padding: 12px 8px;
}

@mixin productTableVariableName {
  font-family: $montserratFontStyle;
  font-weight: $regularFontWeight;
  letter-spacing: -0.7px;
  color: $black60;
  @include smallFont;

  @include lg {
    display: none;
  }
}

@mixin productTableVariableValue {
  font-family: $montserratFontStyle;
  font-weight: $semiBoldFontWeight;
  letter-spacing: -0.5px;
  color: $black100;
  @include middleFont;

  @include lg {
    font-style: normal;
    font-weight: $regularFontWeight;
    @include grossFont;
  }
}

@mixin productsContainer {
  display: grid;
  grid-template-columns:  repeat(1, 1fr);
  grid-gap: 20px;

  @include sm {
    grid-template-columns: repeat(2, 1fr);
  }

  @include lg {
    display: block;
  }
}

@mixin formSectionContainer {
  width: 100%;

  @include xl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 56px;
  }
}

@mixin filterContainer {
  background: $primary2;
  border: 1px solid $primary10;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  max-width: 495px;

  @include sm {
    padding: 32px;
  }
}

@mixin variablesBoxContainer {
  background: $white;
  border: 1px solid $primary20;
  box-sizing: border-box;
  border-radius: 12px;
}
