@import 'src/styles/variables';
@import 'src/styles/mixins';

.popUpItem {
  @include grossFont;
  cursor: pointer;
  padding: 16px 0;
  margin: 0 20px;
  transition: 0.25s all ease-in-out;
  width: 320px;
  font-family: $montserratFontStyle;
  font-weight: $semiBoldFontWeight;
  letter-spacing: -0.2px;
  outline: 0;
  color: $primary100;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  border: none;
  border-bottom: 1px solid $primary20;

  &.selected {
    color: $primary100;
    display: flex;
    align-items: center;
  }
  &:hover{  
    background: $primary2;
  }
  &.main{
    @include h3;
  }
}

