@import 'src/styles/variables';

.popup {
  width: 360px;
}
.mobileView{
  button{
    border-bottom: none;
  }
  button:first-child{
    border-top: 1px solid $primary20;
    border-bottom: 1px solid $primary20;
  }
}