@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  background: linear-gradient(160.65deg, rgba(248, 250, 252, 0.5) 46.35%, #EEF2F7 100%), $white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .headerRow{
    background-color:$white;
    box-shadow: 0 8px 24px -6px rgba(0, 55, 140, 0.08), 0px 0px 1px rgba(0, 55, 140, 0.2);
    display: flex;
    justify-content: center;
    height: 80px;
  }
  .headerBar{
   width: 100%;
   max-width: 1352px;
    display: flex;
    justify-content: space-between;

    .logoutInHeader{
      padding: 16px 32px;

      @include xl {
        padding: 16px 0;
      }

      button{
        padding: 12px;

        p{
          display: none;
          @media screen and (min-width: 470px){
            display: unset;
          }
        }
      }
      p{
        height: 24px;
      }
    }   
  }

}

.content {
  flex-grow: 1;
}
