@import 'src/styles/media';
@import 'src/styles/variables';

.logout{
  background-color: transparent;
  width: 100%;
  svg path:first-child{
    fill: $primary100; 
  }
  circle{
    fill: $primary100;
    stroke: $primary100;
  }
  path:not(:first-child){
    fill: $primary100;
    stroke: $primary100;
  }
}
