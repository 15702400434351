@import 'src/styles/media';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
  background: linear-gradient(160.65deg, rgba(248, 250, 252, 0.5) 46.35%, #E3EAF2 100%), $white;
  justify-content: space-between;
  
  @media screen and (min-width: 370px) and (max-width: 400px){
    height: auto;
  }

  @include md {
    flex-direction: row;
    justify-content: end;
  }
}


.footer {
  display: table;
  margin: 0 32px;
  width: calc(100vw - 64px);
  order:3;
  border-top: 1px solid rgba(204, 207, 211, 0.2);
  height: 68px;
  @include productTableVariableName;
  letter-spacing: unset;

  @include md {
   position: fixed;
    bottom: 0;
    display: table;
    height: 82px;
    width: calc(67vw - 240px);
    right: 0;
    margin: 0 120px;
    color: rgba($color: $white, $alpha: 0.6);
    font-size: 12px;
  }

  >p {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    letter-spacing: -0.7px;
    @include md {
      text-align: unset;
      letter-spacing: normal;
    }
  }

}