@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  width: 100%;
}

.companyAccountsContainer {
  @include formSectionContainer;
}

.sectionTitle {
  @include h3;
  color: $black100;
  margin-bottom: 16px;
}

.inputs {
  flex-grow: 1;
}

.formInput {
  margin-bottom: 8px;
  padding-bottom: 42px;
  @media screen and (min-width: 447px){
    padding-bottom: 22px;
   }
}

.formInput:last-child {
  @include xl {
    margin-bottom: 0;
  }
}

@media print {
  .companyAccountsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
  }

  .inputs {
    & > * {
      padding-bottom: 8px;
    }
  }
}
