@import 'src/components/PrequalificationResultPDF/PrequalificationResultPDF.module.scss';

.container{
  .pageInfo{
    margin-bottom: 4px;
  }
  
  .loanCriteria {
    margin: 8px 0;
  }
  
  .creditProfile {
    margin-bottom: 0;
  
    @include xl {
      margin-bottom: 0;
    }
  }
}
.twoPages{
  .pageInfo{
    margin-bottom: 10px;
  }
  .loanCriteria {
    margin: 14px 0 28px;
  }
  .creditProfile {
    margin-bottom: 28px;
  
    @include xl {
      margin-bottom: 0;
    }
  }
}

.propertyInformation {
  margin-bottom: 28px;

  @include xl {
    margin-bottom: 0;
  }
}

.bottomBar {
  margin-top: 14px;
  margin-bottom: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
}

@media print {
  @page {
    size: B4 portrait;
  }

  .pageBreak {
    margin-top: 1rem;
    display: block;
    clear: both;
    page-break-before: always;
  }
}